<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-4">
        <div class="card">
          <div class="card-body">
            <!-- <b-dropdown
              class="float-end ms-2"
              variant="white"
              right
              toggle-class="text-muted p-0"
              menu-class="dropdown-menu-end"
            >
              <template v-slot:button-content>
                <i class="mdi mdi-dots-horizontal font-size-18"></i>
              </template>
              <b-dropdown-item>Action</b-dropdown-item>
              <b-dropdown-item>Another action</b-dropdown-item>
              <b-dropdown-item>Something else here</b-dropdown-item>
            </b-dropdown> -->
            <div>
              <div class="mb-4 me-3">
                <i class="mdi mdi-account-circle text-warning h1"></i>
              </div>

              <div>
                <h5>Admin</h5>
                <p class="text-muted mb-0">Id no: #1</p>
              </div>
            </div>
          </div>

          <div class="card-body border-top">
            <div class="row">
              <div class="col-sm-6">
                <div>
                  <p class="fw-medium mb-2">Balance :</p>
                  <h4>
                    ${{
                      totalExchange_holding
                        ? totalExchange_holding.toFixed(2)
                        : ""
                    }}
                  </h4>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="mt-4 mt-sm-0">
                  <p class="fw-medium mb-2">Coin :</p>
                  <div class="d-inline-flex align-items-center mt-1">
                    <a
                      href="javascript: void(0);"
                      class="m-1"
                      v-b-tooltip.hover
                      data-placement="top"
                      title="Bitcoin"
                    >
                      <div class="avatar-xs">
                        <span
                          class="
                            avatar-title
                            rounded-circle
                            bg-soft bg-warning
                            text-warning
                            font-size-18
                          "
                        >
                          <i class="mdi mdi-bitcoin"></i>
                        </span>
                      </div>
                    </a>
                    <a
                      href="javascript: void(0);"
                      class="m-1"
                      data-placement="top"
                      v-b-tooltip.hover
                      title="Ethereum"
                    >
                      <div class="avatar-xs">
                        <span
                          class="
                            avatar-title
                            rounded-circle
                            bg-soft bg-primary
                            text-primary
                            font-size-18
                          "
                        >
                          <i class="mdi mdi-ethereum"></i>
                        </span>
                      </div>
                    </a>
                    <a
                      href="javascript: void(0);"
                      class="m-1"
                      data-placement="top"
                      v-b-tooltip.hover
                      title="Litecoin"
                    >
                      <div class="avatar-xs">
                        <span
                          class="
                            avatar-title
                            rounded-circle
                            bg-soft bg-info
                            text-info
                            font-size-18
                          "
                        >
                          <!-- <i class="mdi mdi-litecoin"></i>
                           -->

                          <span v-html="tron"></span>
                        </span>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="card-footer bg-transparent border-top">
            <div class="text-center">
              <a
                href="javascript: void(0);"
                class="btn btn-outline-light me-2 w-md"
                >Deposit</a
              >
              <a href="javascript: void(0);" class="btn btn-primary me-2 w-md"
                >Buy / Sell</a
              >
            </div>
          </div> -->
        </div>
      </div>

      <div class="col-xl-8">
        <div class="card">
          <div>
            <div class="row">
              <div class="col-lg-9 col-sm-8">
                <div class="p-4">
                  <h5 class="text-warning">Welcome Back !</h5>
                  <p>Tacc Exchange Dashboard</p>
                  <p>We will provide what you want!!</p>
                  <!-- 
                  <div class="text-muted">
                    <p class="mb-1">
                      <i
                        class="mdi mdi-circle-medium align-middle text-primary me-1"
                      ></i>
                      If several languages coalesce
                    </p>
                    <p class="mb-1">
                      <i
                        class="mdi mdi-circle-medium align-middle text-primary me-1"
                      ></i>
                      Sed ut perspiciatis unde
                    </p>
                    <p class="mb-0">
                      <i
                        class="mdi mdi-circle-medium align-middle text-primary me-1"
                      ></i>
                      It would be necessary
                    </p>
                  </div> -->
                </div>
              </div>
              <div class="col-lg-3 col-sm-4 align-self-center">
                <div>
                  <img
                    src="@/assets/images/crypto/features-img/img-1.png"
                    alt
                    class="img-fluid d-block"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Bitcoin , Ethereum Section -->
        <div class="row">
          <div class="col-sm-6">
            <div class="card">
              <div class="card-body">
                <p class="text-muted mb-4">
                  <i
                    class="
                      totalExchange_holding
                      fas
                      fa-hand-holding-usd
                      h2
                      text-warning
                      align-middle
                      mb-0
                      me-3
                    "
                  ></i>
                  <strong>Total Exchange Holding</strong>
                </p>

                <div class="row">
                  <div class="col-6">
                    <div>
                      <h5>
                        <!-- {{ total ? this.formatPrice(total.total_holding) : 0 }} -->
                        ${{
                          totalExchange_holding
                            ? totalExchange_holding.toFixed(2)
                            : ""
                        }}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="card">
              <div class="card-body">
                <p class="text-muted mb-4">
                  <i
                    class="
                      fas
                      fa-piggy-bank
                      h2
                      text-warning
                      align-middle
                      mb-0
                      me-3
                    "
                  ></i>
                  <strong>Total Investment</strong>
                </p>

                <div class="row">
                  <div class="col-6">
                    <div>
                      <h5>
                        <!-- {{
                          total ? this.formatPrice(total.total_investment) : 0
                        }} -->
                        ${{
                          totalExchange_holding
                            ? totalExchange_holding.toFixed(2)
                            : ""
                        }}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->

    <div class="row">
      <div class="col-sm-4">
        <div class="card">
          <div class="card-body">
            <p class="text-muted mb-4">
              <i
                class="fas fa-percentage h2 text-warning align-middle mb-0 me-3"
              ></i>
              <strong>Total Commission</strong>
            </p>

            <div class="row">
              <div class="col-6">
                <div>
                  <h5>
                    <!-- {{ total ? this.formatPrice(total.total_commission) : 0 }} -->
                    ${{ totalCommission ? totalCommission.toFixed(2) : 0 }}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="card">
          <div class="card-body">
            <p class="text-muted mb-4">
              <i
                class="
                  fas
                  fa-money-check-alt
                  h2
                  text-warning
                  align-middle
                  mb-0
                  me-3
                "
              ></i>
              <strong>Total Withdraw</strong>
            </p>

            <div class="row">
              <div class="col-6">
                <div>
                  <h5>
                    <!-- {{ total ? this.formatPrice(total.total_withdraw) : 0 }} -->
                    {{ totalWithdraw ? totalWithdraw.toFixed(2) : 0 }}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="card">
          <div class="card-body">
            <p class="text-muted mb-4">
              <i
                class="fas fa-coins h2 text-warning align-middle mb-0 me-3"
              ></i>
              <strong> Today Total Withdraw Commission </strong>
            </p>

            <div class="row">
              <div class="col-6">
                <div>
                  <h5>
                    {{
                      total ? this.formatPrice(total.today_total_withdraw) : 0
                    }}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->

    <div class="row">
      <div class="col-xl-6">
        <WalletBalance title="Top 3 Withdraw Coin" walletcall="top3" />
      </div>
      <div class="col-xl-6">
        <!-- <Overview /> -->
        <WalletBalance
          title="Total Deposit & Withdraw"
          walletcall="depositwithdraw"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-xl-4">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Top Trading 10 Coin</h4>

            <b-card-text>
              <simplebar style="max-height: 330px">
                <div class="float-end">
                  <select
                    class="form-select form-select-sm"
                    @change="changeTop10CoinDuration($event)"
                  >
                    <option value="day" selected>Day</option>
                    <option value="week">Week</option>
                    <option value="month">Month</option>
                  </select>
                </div>
                <table class="table table-centered table-nowrap align-middle">
                  <thead>
                    <tr>
                      <td>Position</td>
                      <td>Currency</td>
                      <td>Quantity</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="loading10coin">
                      <td colspan="3">
                        <div class="text-center">
                          <b-spinner label="Spinning"></b-spinner>
                        </div>
                      </td>
                    </tr>

                    <tr v-for="(data, index) of top10coins" :key="index" v-else>
                      <td>
                        {{ index + 1 }}
                      </td>
                      <td>
                        <!-- <div class="text-end"> -->
                        <h5 class="font-size-14 mb-0">
                          {{ data ? data.currency : "" }}
                        </h5>
                        <!-- </div> -->
                      </td>
                      <td>
                        <h5 class="font-size-14 text-muted mb-0">
                          {{ data ? data.totalQuantity : "" }}
                        </h5>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </simplebar>
            </b-card-text>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>


<script>
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import simplebar from "simplebar-vue";
import WalletBalance from "./wallet-balance.vue";
import ApiClass from "../../../api/api";

import { walletRadialChart, overviewChart, transactionsData } from "./data";

/**
 * Crypto Dashboard Component
 */
export default {
  page: {
    title: "Dashboard",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, simplebar, WalletBalance },
  data() {
    return {
      tron: '<svg id="Calque_1" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 64 64"><defs><style>.cls-1{fill:#ff060a;}</style></defs><title>tron</title><g id="tron"><path class="cls-1" d="M61.55,19.28c-3-2.77-7.15-7-10.53-10l-.2-.14a3.82,3.82,0,0,0-1.11-.62l0,0C41.56,7,3.63-.09,2.89,0a1.4,1.4,0,0,0-.58.22L2.12.37a2.23,2.23,0,0,0-.52.84l-.05.13v.71l0,.11C5.82,14.05,22.68,53,26,62.14c.2.62.58,1.8,1.29,1.86h.16c.38,0,2-2.14,2-2.14S58.41,26.74,61.34,23a9.46,9.46,0,0,0,1-1.48A2.41,2.41,0,0,0,61.55,19.28ZM36.88,23.37,49.24,13.12l7.25,6.68Zm-4.8-.67L10.8,5.26l34.43,6.35ZM34,27.27l21.78-3.51-24.9,30ZM7.91,7,30.3,26,27.06,53.78Z"/></g></svg>',
      walletRadialChart: walletRadialChart,
      overviewChart: overviewChart,
      transactionsData: transactionsData,
      title: "Dashboard",
      items: [
        {
          text: "Dashboard",
          href: "#",
        },
        {
          text: "Dashboard",
          active: true,
        },
      ],
      total: [],
      loading10coin: true,
      top10duration: "day",
      top10coins: [],
      totalCommission: null,
      totalExchange_holding: null,
      totalWithdraw: null,
    };
  },
  mounted() {
    this.totalvalues();
    this.top10coin();
    this.getReports();
  },
  methods: {
    async totalvalues() {
      var result = await ApiClass.getRequest("admin/total/dashboard", true);
      if (result.data.status_code == 1) {
        this.total = result.data.data;
      }
    },

    async getReports() {
      var result = await ApiClass.getNodeRequest(
        "dashboard/get-dashboard-repot",
        true
      );

      if (result.data.status_code == 1) {
        this.totalCommission = result.data.data.total_commission;
        this.totalExchange_holding = result.data.data.total_exchange_holding;
        this.totalWithdraw = result.data.data.total_withdraw;
      }
      // console.log("res==", result);
    },
    async top10coin() {
      this.loading10coin = true;
      var result = await ApiClass.getRequest(
        "admin/total/top10coin/" + this.top10duration,
        true
      );
      if (result.data.status_code == 1) {
        this.loading10coin = false;
        this.top10coins = result.data.data ?? [];
      }
    },
    changeTop10CoinDuration(e) {
      this.top10duration = e.target.value;
      this.top10coin();
    },
    formatPrice(value) {
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
      });
      return formatter.format(value);
    },
  },
};
</script>
